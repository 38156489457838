import React from "react";
import ReactMarkdown from "react-markdown";
import {
  Grid,
  Button,
  SvgIconTypeMap,
  makeStyles,
  Typography,
  Hidden,
} from "@material-ui/core";
import { AppScreenshots } from "./AppScreenshots";
import { AppLink } from "../../components/AppLink/AppLink";
import { MacLink } from "../../components/MacLink/MacLink";
import DevicesIcon from "@material-ui/icons/Devices";
import Card from "@material-ui/core/Card";
import SecurityIcon from "@material-ui/icons/Security";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SpeedIcon from "@material-ui/icons/Speed";
import { FloatingCard } from "./FloatingCard";
import { UpdateNotification } from "../../components/UpdateNotification";
import { CustomMarkdown } from "../../components/CustomMarkdown";

/* eslint-disable import/no-webpack-loader-syntax */
const top = require("!!raw-loader!./markdown/top.md").default;
const appInfo = require("!!raw-loader!./markdown/app_info.md").default;
const reason1 = require("!!raw-loader!./markdown/reason1.md").default;
const reason2 = require("!!raw-loader!./markdown/reason2.md").default;

export function Home() {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: "72px" }}>
        <UpdateNotification />
        <CustomMarkdown source={top} />
        <CallToActionCertificateTransfer />
      </div>
      <Grid wrap="wrap-reverse" container direction="row" justify="center">
        <Grid item xs={12} sm={6} md={6}>
          <AppScreenshots />
        </Grid>
        <Grid item xs={11} sm={5} md={5}>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <div style={{flexGrow: 1}}>a</div> */}
            <Card
              style={{
                padding: "8px 8px 32px 8px",
              }}
            >
              <CustomMarkdown source={appInfo} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ padding: "5px" }}>
                  <AppLink />
                </div>
                <div style={{ padding: "5px" }}>
                  <MacLink />
                </div>
                <div
                  style={{
                    height: "40px",
                    margin: "5px",
                    padding: "8px 11px",
                    fontWeight: "normal",
                    border: "1px solid blue",
                    color: "blue",
                    borderRadius: "5px",
                  }}
                >
                  <a
                    style={{ textDecoration: "none", color: "blue" }}
                    href="https://apps.apple.com/de/app/anwaltspostfach-bea/id1512806756#?mt=8&platform=iphone"
                  >
                    JETZT INSTALLIEREN
                  </a>
                </div>
              </div>
            </Card>
            <br />
            <Typography>
              Mit unserer iPhone-App besteht kein Anlass für unnötige Sorgen.
              Der <b>sichere mobile Zugang¹</b> zum beA und wertvolle{" "}
              <b>ersparte Arbeitszeit</b> beim <b>bea Login</b> wird durch die
              beA App ermöglicht!
            </Typography>
            <div style={{ height: "48px" }} />
          </div>
        </Grid>
      </Grid>
      <Hidden xsDown>
        <Cards />
      </Hidden>
      <div style={{ width: "100%", marginTop: "48px", textAlign: "left" }}>
        <Typography variant="h4">
          beA Login - Super schnell - mit FaceID
        </Typography>
        <ScreenshotImageMail
          alt="bea Login Eingangsbenachrichtigung"
          src="./screenshots/beA-Eingangsbenachrichtigung.png"
        />
        <Typography>
          <ReactMarkdown source={reason1} />
        </Typography>
      </div>

      <div style={{ width: "100%", marginTop: "24px", textAlign: "left" }}>
        <Typography variant="h4">
          beA-Softwarezertifikat erforderlich - befreit Sie (auch am PC) vom
          Kartenlesegerät{" "}
        </Typography>
        <YoutubeVideo />
        <Typography>
          <ReactMarkdown source={reason2} />
        </Typography>
      </div>
    </div>
  );
}

function CallToActionCertificateTransfer() {
  return (
    <div>
      <Button variant="contained" color="primary" href="./certificate">
        <DevicesIcon />
        <div style={{ paddingLeft: "16px" }}>
          Zertifikat auf iPhone übertragen
        </div>
      </Button>
    </div>
  );
}

function Cards() {
  return (
    <div style={{ margin: "48px 0" }}>
      <Grid
        container
        spacing={4}
        direction="row"
        justify="center"
        alignItems="center"
        style={{ marginTop: "16px" }}
      >
        <IconCard
          icon={<SecurityIcon />}
          title="Sicher¹"
          message="Nachrichten und Zertifikat bleiben ausschließlich auf Ihrem Gerät"
        />
        <IconCard
          icon={<SentimentSatisfiedAltIcon />}
          title="Einfach"
          message="Unterwegs beA-Nachrichten lesen"
        />
        <IconCard
          icon={<SpeedIcon />}
          title="Schnell"
          message="beA Login mit FaceID oder Fingerabdruck"
        />
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {},
  icon: {
    "& :first-child": {
      height: "48px",
      width: "48px",
    },
  },
  divider: {
    width: "100px",
    height: "5px",
    borderRadius: "2.5px",
    margin: "8px auto",
    backgroundColor: theme.palette.primary.light,
  },
  text: {
    minHeight: "4.2em",
  },
}));

function IconCard(props: { icon: any; title: any; message: string }) {
  let classes = useStyles();
  return (
    <Grid item xs={8} sm={4} className={classes.card}>
      <Card style={{ padding: "16px" }}>
        <div className={classes.icon}>{props.icon}</div>
        <Typography variant="h5">{props.title}</Typography>
        <div className={classes.divider} />
        <Typography className={classes.text}>{props.message}</Typography>
      </Card>
    </Grid>
  );
}

function ScreenshotImageMail(props: { src: string; alt: string }) {
  return (
    <FloatingCard>
      <img {...props} style={{ width: "100%" }} />
    </FloatingCard>
  );
}

function YoutubeVideo() {
  return (
    <FloatingCard>
      <div
        style={{
          width: "100%",
          position: "relative",
          paddingBottom: "56.25%",
          overflow: "hidden",
        }}
      >
        <iframe
          id="ytplayer"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
          }}
          allowFullScreen={true}
          src="https://www.youtube-nocookie.com/embed/Y2TmX_mix0g?autoplay=0&origin=https://www.anwaltspostfach.app"
          frameBorder="0"
        />
      </div>
    </FloatingCard>
  );
}

function Text(props: any) {
  return <span>{props.children}</span>;
}
