import React, { useState, useCallback } from "react";
import { IconButton } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
const phone1 = require('../../resource/phone_1.png');
const phone2 = require('../../resource/phone_2.png');
const phone3 = require('../../resource/phone_3.png');
const phone4 = require('../../resource/phone_4.png');

export function AppScreenshots() {
  let images = [phone1, phone2, phone3, phone4];
  let [currentImage, setImage] = useState(1);
  let nextImage = useCallback(() => { setImage((currentImage + 1) % images.length); }, [currentImage]);
  let previousImage = useCallback(() => { setImage((images.length + currentImage - 1) % images.length); }, [currentImage]);

  var buttonStyle = {
    padding: "0px"
  };

  var iconStyle = {
    width: "2em",
    height: "2em",
  };

  return <div>
    <div style={{ display: "flex", flexDirection: "row", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
      <IconButton onClick={previousImage} style={buttonStyle}>
        <ChevronLeftIcon style={iconStyle} />
      </IconButton>
      <div>
        <img src={images[currentImage]} style={{maxWidth: "300px", width: "100%"}} />
      </div>
      <IconButton onClick={nextImage} style={buttonStyle}>
        <ChevronRightIcon style={iconStyle} />
      </IconButton>
    </div>
    <div>
      {images.map((img, index) => <IconButton onClick={() => setImage(index)}>
        <FiberManualRecordIcon style={{ opacity: index == currentImage ? 1 : 0.5 }} />
      </IconButton>)}
    </div>
  </div>;
}
