import React from "react";
import Card from '@material-ui/core/Card';

var floatLeft = true;
export function FloatingCard(props: { children: any }) {
  floatLeft = !floatLeft
  return <Card style={{
    margin: `24px ${!floatLeft ? 0 : 24}px 24px ${floatLeft ? 0 : 24}px`,
    float: floatLeft ? 'left' : 'right',
    width: '40%',
  }}>
    {props.children}
  </Card>;
}
