import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import {
  makeStyles,
  Paper,
  createMuiTheme,
  ThemeProvider,
  CssBaseline,
} from "@material-ui/core";
import { Home } from "./pages/home/Home";
import { AppLink } from "./components/AppLink/AppLink";
import { MacLink } from "./components/MacLink/MacLink";
import { Certificate } from "./pages/certificate/Certificate";
import { CustomMarkdown } from "./components/CustomMarkdown";
import { UpdateNotification } from "./components/UpdateNotification";

/* eslint-disable import/no-webpack-loader-syntax */
const markdownFAQ = require("!!raw-loader!./markdown/faq.md").default;
const markdownIPhone = require("!!raw-loader!./markdown/iphone.md").default;
const markdownIPad = require("!!raw-loader!./markdown/ipad.md").default;
const markdownMac = require("!!raw-loader!./markdown/mac.md").default;

const markdownDatenschutz =
  require("!!raw-loader!./markdown/datenschutz.md").default;
const markdownImpressum =
  require("!!raw-loader!./markdown/impressum.md").default;
const markdownNutzungsbedingungen =
  require("!!raw-loader!./markdown/nutzungsbedingungen.md").default;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: "#282f39",
  },
  header: {
    backgroundImage: `url("./bg.jpeg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "400px",
    backgroundPositionY: "60%",
  },
  content: {
    maxWidth: "950px",
    minHeight: "40%",
    margin: "3em auto",
    marginTop: "-150px",
    padding: "2em",
    backgroundColor: "rgb(250, 250, 250)",
    borderRadius: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    "&:visited": {
      color: "white",
    },
    color: "white",
    textDecoration: "none",
  },
}));

function ScreenshotImage(props: string) {
  return (
    <img
      {...props}
      style={{
        maxWidth: "47%",
        margin: "1%",
        boxShadow: "1px 1px 3px 2px rgba(0,0,0,0.3)",
      }}
    />
  );
}

export default function App() {
  return (
    <Router>
      <CssBaseline />
      <Switch>
        <Route path="/app/impressum">
          <Impressum />
        </Route>
        <Route path="/app/faq">
          <Faq />
        </Route>
        <Route path="/app/datenschutz">
          <Datenschutzerklärung />
        </Route>
        <Route path="/app/nutzungsbedingungen">
          <Nutzungsbedingungen />
        </Route>
        <Route path="/">
          <ThemeProvider theme={theme}>
            <Website />
          </ThemeProvider>
        </Route>
      </Switch>
    </Router>
  );
}

function Website() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <NavigationBar />
      </div>
      <Paper className={classes.content}>
        <Switch>
          <Route path="/faq">
            <Faq />
          </Route>
          <Route path="/bea-iphone">
            <BeaIphone />
          </Route>
          <Route path="/bea-ipad">
            <BeaIPad />
          </Route>
          <Route path="/bea-mac">
            <BeaMac />
          </Route>
          <Route path="/certificate">
            <Certificate />
          </Route>
          <Route path="/datenschutz">
            <Datenschutzerklärung />
          </Route>
          <Route path="/impressum">
            <Impressum />
          </Route>
          <Route path="/nutzungsbedingungen">
            {" "}
            {/* not linked on navigation bar. access through app and appstore */}
            <Nutzungsbedingungen />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <div
          style={{
            marginTop: "36px",
            position: "relative",
            float: "right",
            color: "lightgrey",
          }}
        >
          <Link to="/datenschutz" className={classes.link}>
            Datenschutz
          </Link>
          &nbsp;|&nbsp;
          <Link to="/impressum" className={classes.link}>
            Impressum
          </Link>
        </div>
      </Paper>
    </div>
  );
}

function Faq() {
  return (
    <div>
      <UpdateNotification />
      <CustomMarkdown source={markdownFAQ} />
    </div>
  );
}
function BeaIPad() {
  return (
    <div>
      <AppLink />
      <CustomMarkdown
        source={markdownIPad}
        renderers={{ image: ScreenshotImage }}
      />
    </div>
  );
}

function BeaIphone() {
  return (
    <div>
      <AppLink />
      <CustomMarkdown
        source={markdownIPhone}
        renderers={{ image: ScreenshotImage }}
      />
    </div>
  );
}

function BeaMac() {
  return (
    <div>
      <MacLink />
      <CustomMarkdown
        source={markdownMac}
        renderers={{ image: ScreenshotImage }}
      />
    </div>
  );
}

function Datenschutzerklärung() {
  return (
    <div>
      <CustomMarkdown source={markdownDatenschutz} />
    </div>
  );
}

function Impressum() {
  return (
    <div>
      <CustomMarkdown source={markdownImpressum} />
    </div>
  );
}

function Nutzungsbedingungen() {
  return (
    <div>
      <CustomMarkdown source={markdownNutzungsbedingungen} />
    </div>
  );
}

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
