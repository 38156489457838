import { QrStreaming } from "./QrStreaming";
import React from 'react';
import { Typography } from "@material-ui/core";
import { CustomMarkdown } from "../../components/CustomMarkdown";
const noWifiIcon = require('./no-wifi.svg');
/* eslint-disable import/no-webpack-loader-syntax */
const noCertificateUploadNotice = require('!!raw-loader!./markdown/no-upload-notice.md').default

export function Certificate() {
  return <div>
    <CustomMarkdown source={noCertificateUploadNotice} />
    <QrStreaming />
    <br />
    <Typography>Die Übertragung an die App ist auch ohne Netzwerkverbindung möglich.</Typography>
    <img style={{width: "128px", margin: '16px auto', display: 'block'}} src={noWifiIcon} />
  </div>;
}