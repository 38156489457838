import React from "react";
import ReactMarkdown from 'react-markdown';
import { Typography } from "@material-ui/core";

export function CustomMarkdown(props: { source: any; renderers?: ({ [s: string]: any; }); }) {
  return <Typography>
    <ReactMarkdown
      renderers={{
        heading: Heading,
      }}
      {...props} />
  </Typography>;
}

let headings: {[key: number]: (props: any) => any} = {
  1: (props: any) => <h1 {...props} />,
  2: (props: any) => <h2 {...props} />,
  3: (props: any) => <h3 {...props} />,
  4: (props: any) => <h4 {...props} />,
  5: (props: any) => <h5 {...props} />,
  6: (props: any) => <h6 {...props} />,
}

function Heading(props: {level: 1|2|3|4|5|6, children: any}) {
  console.log(props)
  let heading = headings[props.level]
  let text = props.children.map((child: any) => child.props.value).join()
  let name = text
    .toLowerCase()
    .replace(/[\.\?\(\)&,!]*/g, "")
    .replace(/ /g, "-")

  return heading({children: props.children, id: name})
}