import React, {useCallback, useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone'
import {encode} from 'base64-arraybuffer'
import certificateImage from './certificate.svg'
import { Typography } from '@material-ui/core';
const fastChunkString = require('fast-chunk-string');
var QRCode = require('qrcode.react');

// the fastest way
function MyDropzone(props: {chunkedData: (chunks: [string]) => void, chunkSize: number}) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result
        if (typeof binaryStr === "string") {
          props.chunkedData(fastChunkString(binaryStr, {size: props.chunkSize, unicodeAware: false}))
        } else {
          props.chunkedData(fastChunkString(encode(binaryStr as any), {size: props.chunkSize, unicodeAware: false}))
        }
      }
      reader.readAsArrayBuffer(file)
    })
    
  }, [props])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} style={{textAlign: "center", height: "400px",  padding: "16px", backgroundColor: "#eaeaea"}}>
      <br/>
      <Typography>Um das Zertifikat an die App zu übertragen, ziehen Sie das beA Zertifikat per Maus in das Feld. Oder klicken Sie 'Datei auswählen'. Sobald die Zertifikatsdatei eingefügt ist, erscheint ein QR-Code/Barcode.</Typography>
      <br/><br/>
      <input {...getInputProps()} style={{display: "auto"}} />
      <br/><br/><br/>
      <img src={certificateImage} style={{width: "200px"}} alt="" />
      <br />
    </div>
  )
}

function QrStream(props: {data: string[]}) {
  let [position, setPosition] = useState(0)
  let [dataUrl, setDataUrl] = useState("asdasd")
  const [seconds, setSeconds] = useState(0);
  const [isActive,  ] = useState(true);

  const nextQr = useCallback(() => {
    if (props.data.length === 0) {
      return
    }
  
    // Two packets plus one random
    let packets = [position].map((i) => {
      return `{"i":${i},"c":${props.data.length},"d":"${props.data[i]}"}`
    }).join(",")

    setDataUrl(`[${packets}]`)

    setPosition((position + 1) % props.data.length)
  }, [props, position])

  useEffect(() => {
    let interval: any = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
        nextQr()
      }, 600);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, nextQr]);

  if (props.data.length == 0) {
    return null
  }

  return (
    <QRCode
      style={{filter: "drop-shadow(1px 1px 1px rgba(16, 16, 16, .7))" }}
      value={dataUrl}
      renderAs="svg"
      bgColor="rgba(124,240,10,0.0)"
      fgColor="#111"
      size="400"
    />
  )
}

export function QrStreaming() {
  let [qrData, setQrData] = useState<string[]>([])

  let dropZone = <MyDropzone chunkSize={300} chunkedData={(chunks) => {
    console.log(chunks)
    setQrData(chunks)
  }} />

  let qrStream = <div style={{padding: "16px", display: "inline-block"}}>
    <h3>Scanne den Code mit der App</h3>

    <QrStream data={qrData} />
    <button onClick={() => setQrData([])} style={{
      display: "block",
      marginTop: "16px"
    }}>Zurück</button>
  </div>

  return (
    <div className="App">
      <div style={{border: "2px dashed", textAlign: "center"}}>
        {qrData.length === 0 ? dropZone : qrStream}
      </div>
    </div>
  );
}
