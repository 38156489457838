import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    flexGrow: 1,
    backgroundColor: "rgba(40, 47, 57, 0.7)",
    paddingRight: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    padding: "8px 6px",
    fontSize: "20px",
    fontWeight: "bold",
    '&:visited': {
      color: 'white'
    },
    color: 'white',
    textDecoration: 'none',
    textTransform: 'none'
  }
}));

export default function NavigationBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link to="/" className={classes.link}><Button className={classes.link}>App</Button></Link>
      <Link to="/certificate" className={classes.link}><Button className={classes.link}>Zertifikat übertragen</Button></Link>
      <Link to="/faq" className={classes.link}><Button className={classes.link}>Häufige Fragen</Button></Link>
      <Link to="/bea-iphone" className={classes.link}><Button className={classes.link}>iPhone</Button></Link>
      <Link to="/bea-ipad" className={classes.link}><Button className={classes.link}>iPad</Button></Link>
    </div>
  );
}