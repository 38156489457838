import React from "react";
import { CustomMarkdown } from "./CustomMarkdown";

/* eslint-disable import/no-webpack-loader-syntax */
const updateNotification =
  require("!!raw-loader!../markdown/update-notification.md").default;

export function UpdateNotification() {
  return (
    <div className="app-update">
      <CustomMarkdown source={updateNotification} />
    </div>
  );
}
